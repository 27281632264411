.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    border-radius: 10px;
    min-width: 700px;
    color: white;
    background-color: $back-dark;
    animation: fadeIn 0.4s ease;

    .popup-support {
        padding: 10px;
        @include flex(0, center, center, column);

        p {
            font-size: 20px;
            margin: 0;
            margin-bottom: 20px;
        }

        input,
        textarea {
            width: 100%;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 10px;
            background-color: $bright-back;
            color: white;
            border: 2px solid $main;
            text-align: right;
        }

        textarea {
            height: 200px;
        }

        button {
            background-color: $main;
            border-radius: 10px;
            padding: 5px 20px;
            font-size: 20px;
            cursor: pointer;
            color: white;
        }
    }

    .loader-height {
        padding: 20px;
        @include flex(0, flex-start, center, column);
        min-height: 300px;

        img {
            width: 85px;
            aspect-ratio: 1/1;
            margin-bottom: 20px;
        }

        h1 {
            text-align: center;
            margin: 0;
            margin-bottom: 15px;
            font-size: 28px;
        }

        p {
            text-align: center;
            margin: 0;
            margin-bottom: 8px;
            font-size: 20px;
            opacity: 0.8;

            span {
                color: $main;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .loader-wrapper {
            position: relative;
            margin-top: 80px;
        }

        .button-wrapper {
            width: 100%;
            @include flex(70px, center, center);
            margin-top: 40px;

            div {
                @include flex(0, center, center);
                width: 125px;
                height: 35px;
                color: white;
                border-radius: 10px;
                font-size: 22px;
                cursor: pointer;
            }

            .cancel {
                border: 2px solid $bright-back
            }

            .help {
                background-color: $main;
            }
        }
    }

    div {
        .wallet-connect {
            margin-top: 0;
            border-radius: 10px;
            padding-bottom: 0px;
            color: white;

            p {
                padding: 0 10px;
            }

            section {
                border-top: 1px solid gray;
                border-radius: 10px;
                border-bottom-width: 0px;
            }
        }

        .extension-wrapper {
            padding-bottom: 40px;

            .extension {
                height: unset;
                text-align: center;
                padding: 10px;
                border-radius: 10px;
                padding-bottom: 20px;
                color: white;
                background-color: $back-dark;
            }
        }
    }

    .wellcome {
        padding-bottom: 30px;

        h1 {
            font-size: 32px;
            text-align: center;
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    .bought {
        max-width: 700px;
        padding: 15px;
        padding-bottom: 20px;
        text-align: right;

        h1 {
            text-align: center;
            font-size: 26px;
        }

        p {
            font-size: 20px;
            opacity: 0.8;
            text-align: right;
        }
    }
}

.done {
    cursor: pointer;
    background-color: $main;
    font-size: 36px !important;
    color: white;
    padding: 10px 55px !important;
    width: fit-content;
    margin: auto;
    border-radius: 12px;
}

.popup-close-mobile {
    position: absolute;
    bottom: -100px;
    width: 100%;
    @include flex(0, center, center);
    font-size: 32px;
    color: white;
    cursor: pointer;
    opacity: 0.7;
}

@media (max-width: $mobile) {
    .popup {
        min-width: unset;
        width: 95vw;

        .popup-support {
            h1 {
                font-size: 24px;
                margin: 6px;
            }

            p {
                font-size: 16px;
                margin: 0;
                margin-bottom: 10px;
            }

            textarea {
                height: 150px;
            }
        }

        div {
            .wallet-connect {
                section {
                    border-right-width: 0;
                    border-left-width: 0;
                }
            }
        }

        .bought {
            height: 80vh;
            overflow-y: scroll;

            .done {
                font-size: 24px !important;
            }
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
            top: 100vh
        }

        50% {
            opacity: 0.4;
        }

        100% {
            opacity: 1;
            top: 50%;
        }
    }
}
.show-card {
    border-radius: 10px;
    width: 100%;
    background: radial-gradient(circle at center, $back-dark , $bright-back);
    cursor: pointer;

    img {
        width: 100%;
        aspect-ratio: 16/9;
        border-radius: 10px;
        object-fit: cover;
    }

    .title {
        color: $main;
        font-size: 24px;
        text-align: center;
        margin: 0;
        padding: 10px;
    }

    div {
        @include flex(0px, space-around, center);
        padding: 0 10px;
    }
}
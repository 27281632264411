.ac-img {
    position: fixed;
    bottom: 40px;
    left: 10px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
    visibility: var(--visibility);
}

.ac-popup {
    position: fixed;
    bottom: 80px;
    left: 10px;
    width: 330px;
    padding: 5px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #000000;
    z-index: 2;
    @include flex(10px, space-evenly, flex-start);
    flex-wrap: wrap;

    p{
        text-decoration: underline ;
        cursor: pointer;
    }

    button {
        width: 45%;
        @include flex(0, center, center, column);
        cursor: pointer;
        font-size: 22px;
        border-radius: 10px;
        border: 1px solid #bbbbbb;

        span {
            font-size: 30px;
        }
    }

    button:hover {
        outline: 2px black solid;
    }
}

@media (max-width: 700px) {
    .ac-img {
        bottom: 50px;
        width: 40px;
        height: 40px;
    }

    .ac-popup {
        bottom: 100px;
        width: 240px;

        button {
            font-size: 20px;
            height: 70px;

            span {
                font-size: 20px;
            }
        }
    }
}
.error{
    min-height: calc(100vh - 120px);
    @include flex(20px, center, center,column);
    img{
        width: 160px;
    }
    h1{
        color: white;
        font-size: 36px;
        margin: 0;
        text-align: center;
    }
    p{
        color:rgba(255,255,255,0.7);
        font-size: 20px;
        margin: 0;
        text-align: center;
        span{
            color: $main;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    div{
        @include flex(0, center, center);
        font-size: 40px;
        margin-top: 40px;
        color: white;
        background-color: $main;
        padding: 5px 30px;
        border-radius: 10px;
        cursor: pointer;  
    }
}
@media screen and (max-width: $mobile) {
    .error{
        padding: 0 10px;
        margin-top: 100px;
        img{
            width: 100px;
        }
        h1{
            font-size: 22px;
        }
        p{
            font-size: 16px;
        }
        div{
            font-size: 26px;
            padding: 5px 20px;
        }
    }
    
}
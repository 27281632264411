.discover-wrapper {
    max-width: 1180px;
    margin: auto;
    margin-top: 40px;

    .discover-choose {
        @include flex(75px, center, center);
        width: max-content;
        overflow-x: scroll;
        width: 100%;
        margin-bottom: 20px;

        p {
            font-size: 20px;
            opacity: 0.7;
            cursor: pointer;
            margin: 0;
            width: max-content;
            white-space: nowrap;
        }

        .chosen {
            opacity: 1;
            color: $main;
            border-bottom: 2px solid $main;
        }
    }

    .discover {
        width: 1180px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
}

@media (max-width:1200px) {
    .home {
        .discover {
            width: 100%;
            grid-template-columns: 1fr;
            gap: 20px;
        }
    }
}
.creator {
    max-width: 1284px;
    min-height: calc(100vh - 60px);
    margin: auto;
    color: white;

    .banner {
        width: 100%;
        aspect-ratio: 8/1;
        margin-bottom: 30px;
        width: 100%;
        aspect-ratio: 6.5/1;
        object-fit: cover;
        border-radius: 10px;
    }

    .profile-lower {
        width: 100%;
        @include flex(15px, flex-start, center);

        .unfollow,
        .follow {
            position: absolute;
            left: 10px;
            top: 10px;
        }

        .unfollow {
            @include flex(0, center, center);
            background-color: $bright-back;
            width: 150px;
            height: 36px;
            border-radius: 18px;
            cursor: pointer;
        }

        .creator-img {
            width: 170px;
            height: 170px;
            position: relative;
            object-fit: cover;
            border-radius: 50%;
        }

        .details {
            height: 170px;
            @include flex(0, space-between, flex-start, column);
            max-width: 500px;

            p,
            h3 {
                margin: 0;
            }

            h3 {
                all: unset;
                width: 300px;
                color: white;
                background-color: transparent;
                font-size: 32px;
                margin: 0;
                padding-left: 5px;
            }

            .desc {
                font-size: 16px;
                opacity: 0.7;
            }

            .followers {
                color: $main;
            }

            .social {
                @include flex(10px, flex-start, center);
                gap: 10px;
                margin-top: 10px;

                a {
                    img {
                        width: 30px;
                        aspect-ratio: 1/1;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    .line {
        width: 100%;
        height: 1px;
        background-color: $main;
        margin: 20px 0;
        //generate box shadow only bottom small blurry
        box-shadow: 0 1px 2px 0 $main;
    }

    

    .events-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(320px, 25%));
        gap: 5px;
        margin-bottom: 30px;
    }

    .creator-event {
        cursor: pointer;

        img {
            width: 100%;
            aspect-ratio: 16/9;
            border-radius: 10px;
            object-fit: cover;
            margin-bottom: 4px;
        }

        h3 {
            font-weight: 400;
            font-size: 18px;
            margin: 0;
        }

        p {
            font-size: 16px;
            margin: 0;
            opacity: 0.7;
        }
    }

    .no-events {
        margin: auto;
        margin-top: 50px;
        @include flex(10px, center, center, column);
        padding: 0 15px;

        p {
            font-size: 20px;
            text-align: center;
            margin: 0;
        }

        img {
            width: 220px;
        }
    }
}



@media (max-width: $mobile) {
    .creator {
        padding: 0 10px;

        .events-container {
            width: 100%;
            grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
            gap: 40px;
            padding: 0 10px;
        }

        .event-card {
            .event-inner {
                @include flex(10px, flex-start, flex-start);
                font-size: 16px;
                padding: 8px;
            }
        }

        .no-events {
            margin-top: 0px;
            @include flex(10px, center, center, column);

            h1 {
                font-size: 20px;
            }

            img {
                width: 160px;
            }
        }

        .profile-lower {
            width: 100%;
            gap: 6px;
            align-items: flex-start;
            padding-bottom: 40px;

            .follow,
            .unfollow {
                font-size: 18px;
                width: 120px;
                height: 30px;
                left: 50%;
                transform: translateX(-50%);
                top: unset;
                bottom: 0;
                margin: 0;
            }

            .creator-img {
                width: 120px;
                height: 120px;
            }

            .details {
                @include flex(0, flex-start, flex-start, column);
                flex: 1;
                height: unset;

                p,
                h3 {
                    margin: 0;
                    word-break: break-word;
                    width: 100%;
                }

                h3 {
                    font-size: 18px;
                    padding-left: 5px;
                }

                .desc {
                    font-size: 16px;
                    opacity: 0.7;
                }

                .social {
                    @include flex(10px, flex-start, center);
                    gap: 10px;
                    margin-top: 10px;

                    a {
                        img {
                            width: 30px;
                            aspect-ratio: 1/1;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}
.menu {
    position: fixed;
    top: 56px;
    right: 0;
    padding: 16px;
    width: 320px;
    display: flex;
    gap: 25px;
    justify-content: flex-start;
    align-content: center;
    flex-direction: column;
    z-index: 99999;
    font-size: 20px;
    box-shadow: -10px 10px 12px -12px $sec;
    border-bottom: 1px solid $main;
    border-left: 1px solid $main;
    border-bottom-left-radius: 10px;
    background-color: $back-dark;
    color: white;
    animation: slide-left 0.3s ease;

    a{
        color: white;
    }

    div {
        @include flex(10px, flex-start, center);
        cursor: pointer;

        .arrow {
            margin-right: 0;
        }

        .mode {
            height: 18px;
            width: 18px;
            outline: 2px solid $main;
            border-radius: 50%;
        }

        .help-p {
            margin-top: 0;
            cursor: auto;
        }

        .help-opt {
            @include flex(0px, space-between, center);
            width: 100%;
            height: 40px;
            font-size: 14px;
            border: 1px solid;
            padding: 4px 8px;
            border-radius: 4px;
            box-shadow: 0px 3px 4px 0px rgba($color: #909090, $alpha: 0.5);
        }
        .ans{
            font-size: 16px;
            display: flex;
            align-self: flex-start;
            margin:4px;
        }

        a {
            text-decoration: none;
            @include flex(10px, flex-start, center);
        }

        a:hover {
            color: $main;
        }
    }

    .faq{
        overflow-y: scroll;
        padding-bottom: 20px;
    }

    .feedback{
        input,textarea{
            color: white;
            background-color: transparent;
        }
        button{
            background-color: $main;
            color: white;
            padding: 5px 20px;
            border-radius: 10px;
        }
    }

    .close {
        position: absolute;
        top: 12px;
        right: 12px;

        span {
            font-size: 30px;
        }
    }

    .close-mobile {
        position: absolute;
        bottom: 120px;
        margin-left: calc(50% - 16px);
        transform: translate(-50%);

        span {
            font-size: 40px;
        }
    }

    form {
        flex-direction: column;

        p {
            font-size: 22px;
            padding: 0;
            margin-top: 0;
        }

        textarea {
            font-size: 16px;
            height: 150px;
            width: 100%;
            margin-bottom: 10px;
        }

        label {
            font-size: 12px;
            margin-top: 3px;
        }

        .txt {
            width: 100%;
            margin-bottom: 8px;
        }

        button {
            cursor: pointer;
            margin-top: 12px;
            font-size: 20px;
            padding: 3px 6px;
            border-radius: 8px;
        }
    }
}

.left {
    right: unset;
    left: 0;
    border-left: unset;
    border-right: 1px solid $main;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: 10px;
    box-shadow: 10px 10px 12px -12px $sec;


}

@media (max-width: $mobile) {
    .menu {
        width: 100%;
        height: calc(100vh - 7%);
        top: 56px;
        box-shadow: unset;
        border-left: unset;
        border-bottom-left-radius: unset;
        border-width: 0;
        animation: slide-left 0.3s ease;
    }
}

@keyframes slide-left {
    0% {
        right:-320px
    }
    100% {
       right:0;
    }
}
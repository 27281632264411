.popup-edit-profile {
    padding: 0 10px;
    @include flex(0, center, center, column);

    p{
        font-size: 22px;
        margin:10px 0 10px 0;
    }

    input{
        width: 100%;
        height: 40px;
        margin: 10px 0;
        padding: 0 10px;
        border-radius: 10px;
        border:1px solid $main;
        background-color: $bright-back;
        color: white;
        font-size: 22px;
        margin-bottom: 20px;
    }

    img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin: auto;
        cursor: pointer;
        object-fit: cover;
    }

    .buttons-wrapper {
        @include flex(0, center, center);
        margin: 20px 0;

        button {
            width: 100px;
            height: 40px;
            margin: 0 10px;
            border-radius: 10px;
            color: white;
            font-size: 22px;
            border: none;
        }
        .lighter{
            background-color: $bright-back;
        }
    }
}
.profile-history {
    margin-bottom: 30px;

    .no-history-wrapper{
        margin-top: 180px;
    }

    img {
        border-radius: 10px;
        width: 220px;
    }

    table {
        width: 100%;
        border: 1px solid #353840;
        border-radius: 10px;
        background-color: $table-back;

        img {
            width: 20px;
            aspect-ratio: 1/1;
            margin-left: 5px;
        }

        thead {
            border-top-left-radius: 10px;
        }

        td {
            padding: 10px;
            position: relative;

            div {
                width: fit-content;
                @include flex(0px, center, center, column);

                p {
                    margin: 0;
                    margin-bottom: 2px;
                }
            }
        }

        .cancel {
            @include flex(4px, flex-start, center)
        }

        tr {
            font-size: 20px;
            box-shadow: 0px 1px 0px 0px #353840;
        }

        .ticket-holder {
            @include flex(3px, flex-start, center);
        }
    }

    tbody tr:nth-child(even) {
        background-color: $bright-back;
    }

    tbody tr:last-child {
        box-shadow: 0px 0px 0px 0px #818181;
    }

    .cancel-modal {
        position: absolute;
        width: 300px;
        padding: 10px;
        top: -70px;
        transform: translate(0, -40%);
        background-color: $bright-back;
        border-radius: 10px;
        border: 1px solid $main;

        p {
            font-size: 16px;
            text-align: center;
        }

    }
}

.profile-stats {
    @include flex(10px, space-around, center);
    flex-grow: 1;

    div {
        @include flex(15px, flex-start, center, column);

        img {
            height: 160px;
            aspect-ratio: 1/1;
        }

        img,
        svg {
            margin-bottom: 50px;
        }

        h2,
        p {
            margin: 0;
            margin-bottom: 10px;
            font-size: 26px;
        }
    }
}

@media (max-width: $mobile) {
    .profile-history {
        table {
            display: block;
            overflow-x: auto;
            white-space: nowrap;

            td {
                font-size: 14px;
            }
        }

        img {
            width: 160px;
        }

        .no-history-wrapper{
            margin-top: 0;
        }
    }

    .profile-stats {
        @include flex(20px, flex-start, flex-start, column);
        #stat2{
            order:10;
        }

        div {
            @include flex(10px, flex-start, center, row);
            width: 100%;
            border-bottom: none;

            img,
            svg {
                margin-bottom: 0;
                width: 25px;
                height: 25px;
                order: 2;
            }

            h2{
                font-size: 18px;
                order: 1;
                margin: 0;
                font-weight: 100;
            }
    
            p{
                font-size: 22px;
                font-weight: 200;
                order: 3;
                margin: 0;
            }
        }

        div:last-child {
            border-bottom: none;
        }
    }
}
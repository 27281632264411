.popup-upcoming {
    position: fixed;
    z-index: 1000;
    width: fit-content;
    border-radius: 10px;
    color: $main;
    background-color: $bright-back;
    padding: 20px;
    animation: fade 0.5s ease;
    color: white;
    border:1px solid $main;

    .text {
        text-align: center;
        font-size: 28px;
        margin: 0;
        margin-bottom: 30px;
    }

    .nav-wrapper {
        width: 100%;
        @include flex(0, space-evenly, center);

        .action {
            font-size: 28px;
            color: $main;
            text-decoration: underline;
            cursor: pointer;
            margin: 0;
        }

        .close {
            font-size: 28px;
            cursor: pointer;
            margin: 0;
        }
    }
}

.p-bottom {
    bottom: 60px;
    left: 10px;
}

.p-top {
    top: 60px;
    right: 10px;
}

@keyframes fade {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}
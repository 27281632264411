.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    color: white;
    background-color: $bright-back;
    @include flex(40px, space-between, center);
    font-size: 24px;
    z-index: 9;
    padding: 8px 3vw;
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
    direction: ltr;

    .logo {
        height: 35px;
        object-fit: contain;
    }

    .search-placeholder {
        flex: 4;
        @include flex(0, flex-start, center);

        button {
            border-width: 0;
            color: white;
            background-color: transparent;
        }

        span {
            font-size: 28px;
            cursor: pointer;
        }
    }

    .header-options-bar {
        @include flex(30PX, space-between, center);
        position: relative;

        .bar-logo {
            height: 35px;
            object-fit: contain;
            cursor: pointer;
        }

        .header-user-img {
            width: 34px;
            aspect-ratio: 1/1;
            border-radius: 50%;
            margin-top: 4px;
            object-fit: cover;
        }

        .header-user-img:hover {
            outline: 2px solid $main;
        }

        .fifa {
            height: 45px;
        }

        .valorant {
            height: 30px;
        }

        .stream {
            height: 28px;
        }

        .sport {
            height: 38px;
        }
    }

    .header-mobile-bar {
        @include flex(50px, center, center);
        flex: 1;

        .menu-icon {
            font-size: 40px;
        }

        .games-menu {
            object-fit: contain;
            height: 26px;
        }

        .search-icon {
            font-size: 32px;
            flex: 1;
            text-align: right;
        }
    }

    .games-bar {
        position: absolute;
        width: calc(100% + 3vw + 5px);
        top: 40px;
        left: -5px;
        padding: 10px;
        box-shadow: 0px 5px 15px -8px $main;
        background-color: $back-dark;
        @include flex(40px, flex-start, center);
        flex-wrap: wrap;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        animation: slide-d 0.3s ease;

        img {
            width: 30px;
            object-fit: cover;
            aspect-ratio: 1/1;
        }

        span {
            position: absolute;
            top: 4px;
            right: 4px;

        }
    }

    .mobile-search-wrapper {
        height: 40px;
        width: 100%;
        @include flex(5px, center, center);
    }

    .menu-icon {
        font-size: 40px;
    }
}

.footer-mobile {
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 5;
    height: 40px;
    font-weight: 100;
    @include flex(0, space-around, center);
    background-color: $back-dark;
    box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.25);

    img {
        height: 30px;
        object-fit: contain;
    }

    .stream-icon {
        height: 25px;
    }

    .user-img {
        width: 30px;
        height: 30px;
        object-fit: cover;
    }
}

@media (max-width: $mobile) {
    .header {
        @include flex(6px, space-between, center);

        .games-bar {
            width: 100%;
            position: fixed;
            top: 50px;
            right: 0;
            padding: 10px;
            left:unset;
        }
    }
}

@media (max-width: $mobile) {
    .header-mobile-bar {
        gap: 28px !important;
        justify-content: flex-end !important;
    }
}

@keyframes slide-d {
    0% {
        top: 0px;
    }
    100% {
        top: 50px;
    }
}

.premium{
    @include flex(0,center,center);
    .inner{
        width: 700px;
        .posts{
            text-align: center;
            color: $main;
            margin-bottom: 4px;
        }
        .subscribe{
            width: 100%;
            height: 55px;
            border-radius: 27px;
            background-color: $main;
            color: white;
            transition: 0.3s;
            @include flex(0,center,center);
            font-size: 22px;
            cursor:pointer;
        }
        .subscribe:hover{
            background-color: darken($main, 10%);
        }
        .info{
            font-size: 14px;
            opacity: 0.7;
            margin-top: 4px;
        }
    }
}

@media screen and (max-width: $mobile){
    .premium{
        .inner{
            width: 100%;
            .posts{
                font-size: 16px;
            }
            .subscribe{
                font-size: 18px;
                height: 45px;
                border-radius: 22px;
            }
        }
    }
    
}
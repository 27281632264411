.underline {
    text-decoration: underline;
}

.hover-main:hover {
    color: $main;
}

.center {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.center-h {
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rtl {
    direction: rtl;
}

.bold {
    font-weight: 500;
}

.ltr {
    direction: ltr;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.mirror {
    transform: scaleX(-1);
}

.cancel-animation{
    animation: none !important;
}

.outline-bolden {
    outline: 2px solid rgb(255, 255, 255);
}

.center-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column !important;
}

.no-margin{
    margin: 0;
}

.center-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.non-appear {
    display: none;
}

.hidden {
    visibility: hidden;
}

.screen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    background-color: black;
    z-index: 10;
}

.blur {
    opacity: 0.8;
}

.no-scroll {
    overflow-y: hidden;
    height: 100vh;
}

.no-margin {
    margin: 0 !important;
}

.screen-tutorial {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    background-color: black;
    z-index: 10;
    opacity: 0.8;
}

.clickable {
    cursor: pointer;
}

.relative {
    position: relative;
}

.hover-main:hover {
    color: $main;
}

.undecorate {
    text-decoration: none;
}

.circle {
    border-radius: 50%;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input:focus {
    outline: 0px;
}
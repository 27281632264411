$banner-height:300px;
$img-width:150px;
$banner-mobile:240px;
$img-mobile:120px;
.profile {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 120px);
    color:white;
    
    .img-holder {
        @include flex(0px, center, center, column);
        .profile-banner {
            width: 100%;
            height: $banner-height;
            object-fit: cover;
            object-position: bottom;
        }

        .user-img {
            position: absolute;
            top: calc($banner-height - $img-width + calc($img-width/5));
            left: 3%;
            width: $img-width;
            height: $img-width;
            border-radius: 50%;
            object-fit: cover;
            border: 5px solid rgb(233, 233, 233);
        }

        .img-cover {
            @include flex(0px, center, center);
            background-color: black;
            opacity: 0;
            cursor: pointer;

            span {
                color: white;
            }

            input {
                display: none;
            }
        }

        .img-cover:hover {
            opacity: 0.6;
        }
    }

    .profile-wrapper {
        margin-left: 3%;
        margin-right: 3%;
        display: flex;
        flex-direction: column;
        flex-grow:1;

        .details {
            margin-bottom: 20px;
            h1 {
                font-size: 30px;
                margin-bottom: 4px;
                margin-top: 10px;
            }

            div {
                @include flex(10px, flex-start, center);
            }

            .name-holder{
                @include flex(0,space-between,center)
            }

            form {
                display: flex;
                margin: 10px 0;
                *{
                    color: white;
                    background-color: transparent;
                }

                input {
                    height: 33px;
                    font-size: 20px;
                    border-width: 0;
                }

                input:focus {
                    border-width: 0;
                    outline: 0 solid black;

                }

                button {
                    border-width: 0;
                    cursor: pointer;
                }
            }
        }
    }

    .profile-options {
        @include flex(75px, flex-start, center);
        font-size: 24px;
        margin-top: 20px;
        margin-bottom: 30px;
        padding: 15px 0;
        box-shadow : 0px 5px 6px -6px rgba(0,0,0,0.4);
        p {
            margin: 0px;
        }
    }
}

@media (max-width: $mobile) {
    .profile { 
        .img-holder {
            .profile-banner {
                height: $banner-mobile;
            }

            .user-img {
                width: $img-mobile;
                height: $img-mobile;
                top:240px;
                transform: translateY(-50%);
            }

            .profile-wrapper{
                margin-left: 2px;
                margin-right: 2px;
            }
        }

        .profile-options {
            @include flex(0px, space-between, center);
            font-size: 18px;
        }
    }
}
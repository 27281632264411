.popup-video {
    padding: 2px;
    width: 100%;
    height: 393px
}

@media (max-width: $mobile) {
    .popup-video {
        height: 300px;
    }

    .full-screen-button {
        position: absolute !important;
        top: 5px;
        right: 5px;
        color: $main;
        font-size: 20px;
    }

    .full-screen-teaser {
        position: absolute !important;
        left: 45vw;
        width: 100vh;
        height: 100vw;
        transform: translate(-50%, -50%) rotate(90deg);
    }
}
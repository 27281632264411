.event-box-loader {
    @include flex(0, center, center, column);
    border: 4px solid $bright-back;
    border-radius: 15px;
    width: 1180px;

    .event-details {
        width: 100%;
        @include flex(30px, space-between, center);
        padding: 0 20px;
        background-color: $bright-back;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        .img-blank,
        .details-blank {
            width: 100px;
            height: 100px;
            background-color: $back-dark;
        }
        .img-blank {
            border-radius: 50%;
        }
        .details-blank {
            border-radius: 10px;
        }

        .timer-holder {
            @include flex(10px, center, center, column);
            padding: 20px 0;

            p {
                font-size: 32px;
                margin: 0;
            }

            .timer {
                width: 200px;
                background-color: $back-dark;
                height: 30px;
                border-radius: 10px;
            }
        }
    }

    .event-distribution {
        width: 100%;

        h2 {
            margin: 4px auto;
            font-size: 28px;
            font-weight: 400;
            text-align: center;
        }

        .bar-wrapper {
            @include flex(18px, center, center);
            margin-bottom: 30px;

            .distribution-bar {
                width: 70%;
                @include flex(0, center, center);
            }

            .blank-team-bar {
                background-color: $bright-back;
                width: 100%;
                height: 25px;
                border-radius: 10px;
            }

            .blank-percent {
                width: 60px;
                height: 25px;
                background-color: $bright-back;
                border-radius: 10px;
            }
        }
    }

    .event-teams {
        width: 100%;
        @include flex(30px, space-between, center);
        border-radius: 15px;

        .team-1-holder,
        .team-2-holder {
            @include flex(30px, space-around, center);
            border-top: 2px solid $bright-back;
            padding: 10px;
            width: 495px;
            .team-icon {
                width: 105px;
                height: 105px;
                background-color: $bright-back;
                border-radius: 50%;
            }
            .blank-desc{
                width:200px;
                height: 105px;
                background-color: $bright-back;
                border-radius: 10px;
            }  
        }

        .team-1-holder {
            border-right: 2px solid $bright-back;
            border-bottom-right-radius: 15px;
            border-top-right-radius: 15px;
        }

        .team-2-holder {
            border-left: 2px solid $bright-back;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
    }
}

@media (max-width: 1200px) {
    .event-box-loader {
        width: 100%;
        border-radius: 10px;
        width: 95vw;

        .event-details {
            @include flex(0px, space-between, center);
            padding: 0 10px;
            .img-blank,.details-blank{
                width: 80px;
                height: 80px;
            }
        }

        .event-distribution {
            h2 {
                font-size: 16px;
            }

            .bar-wrapper {
                @include flex(10px, center, center);

                p {
                    font-size: 14px;
                }

                .team-red {
                    height: 15px;
                    border-bottom-left-radius: 10px;
                    border-top-left-radius: 10px;
                }

                .team-blue {
                    height: 15px;
                    border-bottom-right-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }
        }

        .choose {
            width: 180px;
            margin: auto;
            margin-bottom: 20px;
        }

        .event-teams {
            width: 100%;
            @include flex(1px, center, center);
            border-radius: 15px;

            .team-1-holder,
            .team-2-holder {
                @include flex(15px, space-between, center);
                padding: 4px;
                width: calc(50% - 4px);
                img {
                    width: 80px;
                }

                div {
                    @include flex(8px, center, center);
                }
            }

            .team-1-holder {
                border-right: 4px solid $bright-back;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0px;
            }

            .team-2-holder {
                border-left: 4px solid $bright-back;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .buy-tickets {
        margin: auto;
        margin-top: 28px;

        .pay {
            width: 250px;
            font-size: 16px;
            padding: 16px;
        }

        .edit {
            @include flex(10px, center, center);
            padding: 6px 4px;

            input {
                width: 52px;
                font-size: 20px;
            }

            div {
                @include flex(0, center, center);
                font-size: 24px;
                padding: 15px;
                height: 18px;
                width: 18px;
            }
        }
    }
}

@media (max-width: 700px) {
    .event-box-loader {
        .event-details {
            
            .timer-holder {
                p {
                    font-size: 20px;
                }

                .timer {
                    width: 120px;
                }
            }
  
        }
        .event-teams {
            width: 100%;
            @include flex(1px, center, center);
            border-radius: 15px;
            .team-1-holder,
            .team-2-holder {
                .team-icon {
                    width: 60px;
                    height: 60px;
                }
                .blank-desc{
                    width:100px;
                    height: 60px;
                }  
            }
        }
    }
}

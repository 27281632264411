.tickets-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    padding: 0 10px;
    gap: 20px;

    h3 {
        font-size: 24px;
        margin: 0;
        color: white;
    }

    .ticket-card {
        border-radius: 10px;
        border: 2px solid $bright-back;
        cursor: pointer;

        img {
            width: 100%;
            aspect-ratio: 2/1;
            border-radius: 10px;
            object-fit: cover;
        }

        .vs-wrapper {
            width: 100%;
            padding: 10px;
            color: white;
            @include flex(30px, flex-start, center);

            p {
                padding: 0 5px;
                background: radial-gradient(circle at center, $sec , $main);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                font-size: 24px;
                font-weight: 900;
                margin: 0
            }
        }

        .small-player {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            object-fit: cover;
        }

        a {
            width: 100%;
            font-size: 24px;
            color: white;
        }

        .timer {
            @include flex(10px, center, center, row);
            width: 100%;
            border-radius: 5px;
            padding: 8px;
            background-color: $bright-back;
            transition: 0.4s;
        }

        .timer:hover{
            background-color: $main;
        }

        .watch-wrapper {
            @include flex(10px, center, center, row);
            width: 100%;
            border-radius: 8px;
            animation: switchColor 1.5s linear infinite;
            padding: 8px;
            cursor: pointer;
        }

        p {
            color: white;
            padding: 0 5px;
        }
    }
}

@keyframes switchColor {
    0% {
        background-color: $bright-back;
    }

    25% {
        background-color: $sec;
    }

    50% {
        background-color: $main;
    }

    100% {
        background-color: $bright-back;
    }
}

.no-tickets-wrapper {
    height: calc(100vh - 60px);
    @include flex(0, center, center);
    color: white;

    .no-tickets {
        @include flex(10px, center, center, column);

        img {
            width: 300px;
            margin: auto;
        }

        p {
            text-align: center;
            font-size: 30px;
            margin: 0;
        }
    }
}

.no-tickets-wrapper {
    height: calc(100vh - 120px);

    .no-tickets {
        img {
            width: 160px;
        }

        p {
            font-size: 24px;
            padding: 0 10px;
        }
    }
}

@media (max-width: 930px) {
    .tickets-container {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}
.chat-box {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    border-left: 1px solid;
    box-shadow: 0px 0px 10px 0px #000000;
    position: static;
    width: 370px;

    .chat-header {
        @include flex(0, space-between, center);
        position: relative;
        flex: 3;
        border-bottom: 5px solid $bright-back;
        margin-top: 12px;
        padding: 0 10px;

        h1 {
            font-size: 20px;
            margin: 0;
            color: white;
            font-weight: 300;
        }

        img {
            width: 20px;
            height: 20px;
        }

        .scrollers {
            @include flex(5px, center, center);
            visibility: hidden;
        }
    }
}

.body-text {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    flex: 32;
    background-color: $back-dark;
    padding-top: 10px;
    word-break: break-word;
}

.chat-box-input {
    display: flex;
    flex: 6;
    align-items: center;
    justify-content: center;
    padding: 0 5px;

    input {
        flex: 1;
        height: 40px;
        border: none;
        background-color: $bright-back;
        padding-right: 20px;
        color: #ffff;
        border-radius: 10px;
        font-size: 16px;
        margin-right: 10px;
    }

    input:focus {
        border: 2px solid $main;
        padding-left: 18px;
    }

    ::placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-size: 16px;
    }

    button {
        padding: 5px 6px;
        border-radius: 50%;
        background-color: $main;
        border: none;
        color: white;
        cursor: pointer;

        span {
            font-size: 22px;
        }
    }
}

.message-div {
    text-overflow: "ellipsis";
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 10px;

    span {
        margin: 0;
        margin-left: 0px;
        color: #ffff;
        font-size: 16px;
    }

}

.nickName {
    margin-right: 5px;
    font-size: 20px;
}

.message-body {
    color: rgba(255, 255, 255, 0.85);
    margin-left: 5px;
    font-size: 16px;
    margin-right: 5px;

}

.avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
}

@media only screen and (max-width: 900px) {
    .chat-box {
        width: unset;
        display: flex;
        flex: 3;
        min-height: 250px;

        .chat-header {
            padding: 0 8px 8px 8px;
            flex: 0;
        }

        .body-text {
            flex: 8 !important;
        }

        .chat-box-input {
            display: flex;
            flex: 0;
            align-items: center;
            justify-content: center;
    
            input {
                width: 80%;
                margin-bottom: 0px;
                border: none;
                background-color: $bright-back;
                padding-left: 20px;
                font-size: 16px;
                color: #ffff;
                border-radius: 10px;
            }
    
            button {
                margin-bottom: 0px;
            }
        }
    }
}

@media only screen and (max-height: 750px) {
    .chat-box {
        .chat-header {
            .scrollers {
                visibility: visible;
            }
        }
    }
}

// make the scroll bar transperent
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* make scrollbar transparent */
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: transparent;
}

::-webkit-scrollbar-thumb:hover {
    background: transparent;
}
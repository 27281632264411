.landing {
    @include flex(0, space-between, center, column);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    .half {
        width: 100%;
        @include flex(0, center, center, column);
    }

    .logo-vertical {
        width: 75px;
        object-fit: contain;
        margin-top: 20px;
    }

    h1 {
        font-size: 50px;
        font-weight: 700;
        color: $main;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 0 20px;
        border-radius: 10px;
    }

    h3 {
        font-size: 28px;
        color: white;
        font-weight: 400;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 0 20px;
        border-radius: 10px;
        margin-top: 0;
    }

    .info {
        padding-bottom: 20px;
    }

    .btn {
        background-color: $main;
        height: 52px;
        width: 362px;
        border-radius: 10px;
        @include flex(0, center, center);
        font-size: 26px;
        font-weight: 500;
        cursor: pointer;
    }
}

@media screen and (max-width: $mobile) {
    .landing {

        .logo-vertical {
            width: 55px;
            margin-top: 10px;
        }

        h1 {
            font-size: 28px;
            font-weight: 00;
        }

        h3 {
            font-size: 22px;
            color: white;
        }

        .info {
            padding-bottom: 25px;
        }

        .btn {
            height: 52px;
            width: 100%;
            font-size: 26px;
        }
    }

}
.search-bar {
    @include flex(0, flex-start, center);
    margin-top: 2px;
    flex: 4;
    border-width: 0;
    border-bottom: 1px solid $main;
    box-shadow: 0px 14px 14px -12px $sec;
    z-index: 100;

    input {
        background-color: inherit;
        font-size: 18px;
        height: 100%;
        flex: 1;
        border-width: 0;
        border-radius: 10px;
        margin-left: 15px;
        color: white;
        text-align: right;
    }

    input:focus {
        outline: 0px;
    }

    button {
        border-width: 0;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0);

        span {
            padding: 0;
        }
    }
}

.search-icon {
    cursor: pointer;
    width: 25px;
}

@media (max-width: 400px) {
    .search-bar {
        input {
            font-size: 14px;
        }
    }
}
.event-box {
    @include flex(0, center, center, column);
    border: 4px solid $bright-back;
    border-radius: 15px;
    width: 1180px;

    .choose3 {
        width: 165px;
        margin-top: 15px;
    }
    .choose-bar-description{
        font-size: 30px;
        font-weight: 100;
        color: rgba(255, 255, 255, 0.5);
        margin:20px;
        margin-bottom: 25px;
    }
    .change-button{
        font-size: 32px;
        margin: 0;
        font-weight: 100;
        color: $main;
        margin-right: 30px;
    }
    .choose-bar {
        @include flex(10px, space-around, center);
        width: 60%;
        padding: 20px 0px;
        border:4px solid $bright-back;
        border-radius: 10px;
        margin-bottom: 20px;
        cursor: pointer;
        div {
            @include flex(10px, center, center);
            img{
                width: 75px;
                aspect-ratio: 1/1;
                object-fit: cover;
                border-radius: 50%;
            }
            p{
                margin: 0;
                font-size: 32px;
                font-weight: 100;
            }
        }
        p{
            font-size: 36px;
            font-weight: 100;
            margin: 0;
        }
    }

    .event-details {
        width: 100%;
        @include flex(30px, space-between, center);
        background-color: $bright-back;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        padding: 0 20px;

        .name-img{
            @include flex(20px, center, center);
            p{
                font-size: 36px;
                margin: 0;
            }
        }

        .event-img {
            width: 100px;
            aspect-ratio: 1/1;
            object-fit: cover;
        }

        .timer-holder {
            @include flex(10px, center, center, column);
            padding: 20px 0;
            padding-left: 30px;

            p {
                font-size: 32px;
                margin: 0;
            }

            .timer {
                @include flex(0, center, center);
                font-size: 44px;
                margin: 0;
                padding: 0;
            }
        }

        .pricepool-holder {
            @include flex(8px, center, flex-start, column);
            padding: 20px 0;
            padding-top: 0;

            div {
                @include flex(10px, flex-start, center);

                .total-pricepool {
                    font-size: 22px;
                }
            }

            .coins {
                height: 40px;
                aspect-ratio: 1/1;
            }

            .sand-watch {
                height: 50px;
                width: 40px;
                object-fit: cover;
            }

            .date-holder {
                @include flex(10px, flex-start, flex-start, column);

                p {
                    margin: 0;
                    text-align: center;
                }
            }
        }
    }

    .event-distribution {
        width: 100%;

        h2 {
            margin: 4px auto;
            font-size: 28px;
            font-weight: 400;
            text-align: center;
        }

        .bar-wrapper {
            @include flex(18px, center, center);
            margin-bottom: 30px;

            p {
                font-size: 32px;
                font-weight: 400;
                margin: 0;
            }

            .distribution-bar {
                width: 70%;
                @include flex(0, center, center);
            }

            .team-red {
                background-color: #be1111;
                height: 25px;
                border-bottom-left-radius: 20px;
                border-top-left-radius: 20px;
            }

            .team-blue {
                background-color: #1e12a8;
                height: 25px;
                border-bottom-right-radius: 20px;
                border-top-right-radius: 20px;
            }
        }
    }

    .event-teams {
        width: 100%;
        @include flex(30px, space-between, center);
        border-radius: 15px;

        .choose {
            width: 136px;
        }

        .team1-glow {
            box-shadow: 4px -3px 6px -2px $main;
        }

        .team2-glow {
            box-shadow: -4px -3px 6px -2px $main;
        }

        .team-1-holder,
        .team-2-holder {
            @include flex(30px, space-between, center);
            border-top: 2px solid $bright-back;
            padding: 10px;
            width: 495px;

            div {
                @include flex(8px, center, center);
            }

            h2 {
                font-size: 42px;
                margin: 0;
            }

            p {
                font-size: 24px;
                max-width: 150px;
            }
        }

        .team-1-holder {
            border-right: 2px solid $bright-back;
            border-bottom-right-radius: 15px;
            border-top-right-radius: 15px;

            h2 {
                margin-right: 30px;
            }
        }

        .team-2-holder {
            border-left: 2px solid $bright-back;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;

            p {
                text-align: right;
            }

            h2 {
                margin-left: 30px;
            }
        }

        .team-icon {
            width: 105px;
            aspect-ratio: 1/1;
            object-fit: cover;
            border-radius: 50%;
        }
    }
}

.buy-tickets {
    margin: auto;
    @include flex(30px, center, center);
    margin-top: 20px;

    .pay {
        @include flex(10px, space-between, center);
        background-color: $main;
        font-size: 32px;
        padding: 14px 12px;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.35);

        p {
            margin: 0;
        }

        .tickets-price {
            flex: 1;
            text-align: center;
        }
    }

    .edit {
        @include flex(6px, center, center);
        color: $sec;
        border: 3px solid $sec;
        border-radius: 5px;
        padding: 10px 8px;
        box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.35);

        input,
        label {
            border: 0;
            color: white;
            background-color: rgba(1, 1, 1, 0);
            width: 80px;
            font-size: 32px;
            text-align: center;
            font-weight: 700;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type="number"] {
            -moz-appearance: textfield;
        }

        div {
            @include flex(0, center, center);
            font-size: 28px;
            padding: 5px;
            height: 38px;
            width: 38px;
            border: 3px solid $sec;
            border-radius: 50%;
            cursor: pointer;

            span {
                font-weight: 700;
            }
        }
    }
}

@media (max-width: 1200px) {
    .event-box {
        width: 100%;
        border-radius: 10px;
        width: 95vw;

        .event-details {
            @include flex(0px, space-between, center);
            padding: 0 10px;

            .date-holder {
                gap: 0 !important;
            }
        }

        .event-distribution {
            h2 {
                font-size: 16px;
            }

            .bar-wrapper {
                @include flex(10px, center, center);

                p {
                    font-size: 14px;
                }

                .team-red {
                    height: 15px;
                    border-bottom-left-radius: 10px;
                    border-top-left-radius: 10px;
                }

                .team-blue {
                    height: 15px;
                    border-bottom-right-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }
        }

        .choose {
            width: 180px;
            margin: auto;
            margin-bottom: 20px;
        }

        .event-teams {
            width: 100%;
            @include flex(1px, center, center);
            border-radius: 15px;

            .team-1-holder,
            .team-2-holder {
                @include flex(15px, space-between, center);
                padding: 4px;
                width: calc(50% - 4px);
                img {
                    width: 80px;
                }

                div {
                    @include flex(8px, center, center);
                }
            }

            .team-1-holder {
                border-right: 4px solid $bright-back;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0px;
            }

            .team-2-holder {
                border-left: 4px solid $bright-back;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .buy-tickets {
        margin: auto;
        margin-top: 28px;

        .pay {
            width: 185px;
            font-size: 16px;
            padding: 16px;
        }

        .edit {
            @include flex(10px, center, center);
            padding: 6px 4px;

            input {
                width: 52px;
                font-size: 20px;
            }

            div {
                @include flex(0, center, center);
                font-size: 24px;
                padding: 15px;
                height: 18px;
                width: 18px;
            }
        }
    }
}
@media (max-width: 900px) {
    .event-box {
        .choose-bar-description{
            font-size: 14px;    
            font-weight: 400;
       
            text-align: center;
          
        }
        .choose3{
            width: 120px;
        }
        .change-button{
           display: none;
        }
        .choose-bar {
            @include flex(10px, space-around, center);
            width: 320px;
            padding: 5px 0px;
            border:3px solid $main;
            border-radius: 10px;
            margin-bottom: 20px;
            cursor: pointer;
            div {
                @include flex(10px, center, center);
                img{
                    width: 55px;
                    aspect-ratio: 1/1;
                    object-fit: cover;
                    border-radius: 50%;
                }
                p{
                    margin: 0;
                    font-size: 18px;
                    font-weight: 100;
                }
            }
            p{
                font-size: 24px;
                font-weight: 100;
                margin: 0;
            }
        }
    }
}

.tutorial-start{
    color:$main;
    width: 100%;
    text-align: center;
    margin-top:20px;
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 700px) {
    .event-box {
        .choose-bar-description{
            font-size: 18px;
            font-weight: 400;
       
            text-align: center;
          
        }
        .event-details {
            .event-img-wrapper {
                width: 89px;

                .event-img {
                    width: 40px;
                }
            }

            .timer-holder {
                @include flex(0, center, center, column);
                padding: 10px 0;
                padding-left: 0px;
                p {
                    font-size: 20px;
                }

                .timer {
                    font-size: 24px;
                }
            }

            .pricepool-holder {
                padding: 10px 0;

                .coins {
                    height: 20px;
                }

                .sand-watch {
                    height: 25px;
                    width: 20px;
                }

                .watchers{
                    height: 15px;
                    width: 15px;
                }

                p {
                    font-size: 12px;
                    margin: 0;
                }
            }
        }
        .event-teams {
            width: 100%;
            @include flex(4px, center, center);
            border-radius: 15px;
            
            .team-1-holder,
            .team-2-holder {
                width: calc(50% - 6px);
                height: 100%;
                gap: 4px;

                h2 {
                    font-size: 14px;
                    margin: 0;
                }

                p {
                    font-size: 14px;
                    max-width: 150px;
                }

                .vertical{
                    @include flex(4px, center, center, column);
                    p,h2{
                        margin: 0;
                    }
                    h2{
                        color:$main;
                    }
                }  
            }

            .team-1-holder {
                h2 {
                    margin-right: 30px;
                }
            }

            .team-2-holder {
                p {
                    text-align: right;
                }

                h2 {
                    margin-left: 10px;
                }
            }

            .team-icon {
                width: 50px !important;
            }
        }

        .choose {
            width: 110px;
        }
    }

    .buy-tickets {
        flex-direction: column;
    }

    .tutorial-start{
        position: absolute;
        top: -25px;
        right:0;
        color:$main;
        width: unset;
        margin-top:unset;
    }
}

.countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 35px;

    .item {
        width: 88px;
        height: 110px;
        border: 1px solid white;
        border-radius: 10px;
        @include flex(0, center, center, column);
        background-color: rgba(255, 255, 255, 0.7);
    }

    .number {
        font-size: 32px;
        color: black;
        font-weight: 700;
        margin: 0;
        margin-bottom: 10px;
        padding-bottom: 10px;
        width: 75px;
        text-align: center;
        border-bottom: 2px solid black;
    }

    .time {
        margin: 0;
        font-size: 18px;
        color: black;
        font-weight: 100;
    }
}

.stream {
    .countdown {
        .item {
            background-color: rgba(255, 255, 255, 0);
            border-width: 0;
        }

        .number {
            color: $main;
            font-size: 32px;
            border-bottom: 1px solid $main;
        }

        .time {
            color: $main;
            font-size: 18px;
        }
    }
}

@media screen and (max-width: $mobile) {
    .countdown {
        gap: 15px;

        .item {
            width: 60px;
            height: 75px;
            border: 1px solid white;
            border-radius: 10px;
        }

        .number {
            font-size: 28px;
            margin-bottom: 4px;
            padding-bottom: 4px;
            width: 55px;
            border-bottom: 1px solid black;
        }

        .time {
            margin: 0;
            font-size: 14px;
        }
    }

}
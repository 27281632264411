@font-face {
    font-family: rubik-regular;
    src: url("./fonts/rubik-regular.ttf") format("opentype");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: rubik-medium;
    src: url("./fonts/rubik-medium.ttf") format("opentype");
    font-weight: 500;
    font-display: swap;
}
.popup-details{
    @include flex(18px, flex-start, flex-start,column);
    h3{
        margin: 4px 0 4px 0;
        font-weight: 100;
        color: white;
        text-align: left;
        font-size: 16px;
        opacity: 0.7;
    }
    p{
        opacity: 1;
        font-size: 18px;
    }
    .gender-wrapper{
        width: 100%;
        margin-bottom: 10px;
        @include flex(0,space-between,center);
        .gender-line{
            @include flex(2px,center,center);
            div{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 2px solid $bright-back;
                padding-left: 1px;
                span{
                    color: $main;
                }
            }
        }
    }

    select{
        width: 100%;
        height: 40px;
        border-radius: 10px;
        border: 2px solid $bright-back;
        background-color: $back-dark;
        color: $main;
        font-size: 18px;
    }
    select:focus{
        outline: none;
    }
    .interests-wrapper{
        width: 100%;
        @include flex(4px,flex-start,flex-start);
        flex-wrap: wrap;

        div{
            border-radius: 5px;
            border-width: 1px;
            border-style: solid;
            text-align: center;
            padding: 3px 5px;
            color: white;
            font-size: 16px;
            opacity: 0.8;
            color:white;
            border-color: white;
        }

        .highlighted{
            border-color: $main;
            color: $main;
        }
    }
}
.button-85 {
    padding: 0;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    @include flex(0, center, center);
    margin: auto;
    cursor: pointer;
    border-radius: 10px;
    color: white;
    font-size: 24px;
    width: 150px;
    height: 36px;
    border-radius: 18px;
    text-align: center;
    margin-bottom: 20px;
}

.button-85:before {
    content: "";
    background: linear-gradient(45deg,
            #ff0000,
            #ff7300,
            #fffb00,
            #48ff00,
            #00ffd5,
            #002bff,
            #7a00ff,
            #ff00c8,
            #ff0000);
    position: absolute;
    top: -1px;
    left: -1px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
}

.button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle at center,
            darken($bright-back, 5%) 0,
            $bright-back 20%,
            lighten($bright-back, 5%) 40%,
            $bright-back 60%,
            lighten($bright-back, 5%) 80%,
            $bright-back 100%);
    background-position: calc((100 - var(--mouse-x, 0)) * 1%) calc((100 - var(--mouse-y, 0)) * 1%);
    background-size: 200% 200%;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing-button-85 {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}
:root {
    --bar-display: flex;
}

.stream-full-phone {
    width: 100%;
    height: 100%;
    @include flex(0, center, center);
    position: fixed;

    p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 20px;
        width: 90%;
        text-align: center;
    }
}

.stream-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    background-color: $back-dark;
    height: calc(100vh - 50px);

    .stream-video {
        h1 {
            font-size: 16px;
        }

        .timer-wrapper {
            @include flex(0, center, center, column);
            .timer {
                font-size: 25px;
                margin-top: 20px;
            }

            .live-in {
                width: 80%;
                object-fit: contain;
            }
        }
    }
}

.stream {
    display: flex;
    flex: 18;
    flex-direction: column;

    .over-video {
        z-index: 1001;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include flex(0, center, center, column);
        background-color: #000000;

        .cant-see {
            font-size: 22px;
        }
    }

    .stream-video {
        @include flex(0, center, center);
        flex: 18;
        background-color: #000000;
        position: relative;

        h1 {
            font-size: 48px;
            text-align: center;
            color: white;
            margin: 8px;
        }

        p {
            font-size: 16px;
            text-align: center;
            color: white;

            span {
                color: $main;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .timer {
            @include flex(0, center, center);
            font-size: 80px;
            margin: 0;
            padding: 0;
            color: white;
            margin-top: 20px;
        }

        video {
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
            max-height: 1040px;
        }
    }

    .mute-pos {
        position: absolute;
        bottom: 5px;
        right: 5px;
        z-index: 1;
        @include flex(5px, center, center);

        svg {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }
}

.stream-info {
    flex: 3;
    @include flex(0, space-between, center);
    box-shadow: 0px -4px 0px 0px #000000;
    padding: 0 10px;

    .chosen {
        color: white;
        @include flex(10px, center, center);
        height: fit-content;
        padding: 0 18px;

        img {
            width: 85px;
            aspect-ratio: 1/1;
            border-radius: 50%;
            object-fit: cover;
        }

        .align-right {
            align-items: flex-end;
        }

        div {
            height: 85px;
            @include flex(0, space-around, flex-start, column);

            h4 {
                font-size: 20px;
                margin: 0;
                display: block;
            }

            p {
                font-size: 16px;
                margin: 0;
                display: block;
                color: $main;
                text-decoration: underline;
                cursor: pointer;
            }

            h5 {
                font-size: 20px;
                margin: 0;
                display: block;
            }

            h6 {
                font-size: 16px;
                margin: 0;
                color: rgba($color: #ffffff, $alpha: 0.9);
                display: block;
            }
        }
    }

    .details {
        @include flex(80px, center, center);
        margin-right: 10px;

        div {
            @include flex(0, center, center);

            img {
                width: 30px;
                height: 30px;
                margin-right: 15px;
                margin-left: 3px;
            }

            p {
                font-size: 28px;
                margin: 0;
                color: white;
            }
        }
    }

    .details-fund {
        @include flex(20px, center, center);
        span{
            @include flex(0, center, center);
            cursor: pointer;
            color: $main;
            font-size: 30px;
        }
    }
}

.vid-control {
    display: none;
}

.events-bar {
    display: flex;
    width: fit-content;
    padding-right: 30px;
    padding-top: 10px;
    flex-direction: column;
    border-left: 1px solid;
    align-items: center;
    box-shadow: 0px 0px 10px 0px #000000;
    position: static;
    overflow-y: scroll;
}

.events-bar-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10px;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    gap: 5px;
    cursor: pointer;

    .team-icon {
        width: 60px;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: 50%;
        margin-bottom: 10px;
    }

    .img-selected {
        outline: 2px solid $main;
    }

    div {
        @include flex(0, space-evenly, flex-start, column);
        height: 100%;

        P {
            color: white;
            font-size: 18px;
            margin: 0;
        }

        h5 {
            color: #ffffff;
            margin: 0;
            font-size: 18px;
            font-weight: 100;
        }

        .timer {
            @include flex(0, center, center);
            font-size: 14px;
            color: #dddddd;
        }
    }
}

@media only screen and (max-width: 1400px) {
    .events-bar-child {
        flex-direction: column;

        p {
            text-align: center;
            color: #ffffff;
        }
    }
}

@media only screen and (max-width: 900px) {
    .stream-container {
        flex-direction: column;
        height: calc(100vh - 60px);
        min-height: 750px;
        padding-bottom: 10px;
        flex-basis: auto;
        flex: 0;

        .stream {
            flex: 2;

            .stream-video {
                @include flex(10px, center, center);
                background-size: cover;
                flex: 1;

                h1 {
                    font-size: 24px;
                }

                .timer-wrapper {
                    .timer {
                        font-size: 25px;
                        margin-top: 20px;
                    }

                    .live-in {
                        width: 70vw;
                    }
                }
            }

            .stream-info {
                flex: 0;

                .chosen {
                    padding: 0 10px;

                    img {
                        width: 30px !important;
                    }

                    div {
                        height: 50px;

                        h4 {
                            font-size: 14px;
                        }

                        p {
                            font-size: 12px;
                        }

                        h5 {
                            font-size: 15px;
                            font-weight: 200
                        }
                    }
                }

                .details {
                    @include flex(4px, center, center, column);
                    margin-right: unset;

                    div {
                        img {
                            width: 15px;
                            height: 15px;
                        }

                        p {
                            font-size: 12px;
                            margin: 0;
                            color: white;
                        }
                    }
                }

                .details-fund {
                    @include flex(10px, center, center);
                    padding-right: 10px;

                    p {
                        font-size: 14px !important;
                    }
                }
            }

            .vid-control {
                @include flex(10px, space-between, center);
                position: absolute;
                bottom: 0;
                padding: 0 10px;
                height: unset !important;
                z-index: 1000;
                width: 100%;

                .mute {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    margin-bottom: 2px;
                }

                .mute-bigger {
                    width: 22px;
                    height: 22px;
                }

                .full-button {
                    @include flex(30px, center, center);

                    .smaller {
                        font-size: 20px;
                    }

                    span {
                        font-size: 26px;
                        font-weight: 400;
                        color: $main;
                    }

                    p {
                        font-size: 14px;
                    }

                    .ttt {
                        transition: transform 0.4s ease-out;
                        animation: colorAlternate 1s ease-in-out infinite alternate;
                    }

                    .slide-out {
                        transform: translateX(300px);
                    }

                }
            }
        }
    }

    @keyframes colorAlternate {
        0% {
            color: white;
        }

        100% {
            color: $main;
        }
    }

    .cant-see {
        font-size: 16px !important;
    }
}

.full-screen {
    position: fixed !important;
    top: 50vh;
    left: 50vw;
    width: 98vh;
    height: 100vw;
    transform: translate(-50%, -50%) rotate(90deg);
    z-index: 1001;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: transparent;
}

::-webkit-scrollbar-thumb:hover {
    background: transparent;
}
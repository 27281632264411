.show-subscriptions {
    @include flex(10px, flex-start, center);
    cursor: pointer;
}

.subs-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

.sub-wrapper {
    position: relative;

    .change-sub {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $main;
        font-size: 28px;
        cursor: pointer;
    }

    .cancel {
        color: red;
        text-decoration: underline;
        cursor: pointer;
    }

    .default-cursor {
        cursor: default;
        animation: flip-vertical-right 0.3s;
    }

    .creator-card {
        animation: flip-vertical-right 0.3s;
    }
}

@keyframes flip-vertical-right {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(180deg);
    }
}


@media screen and (max-width: $mobile) {
    .subs-container {
        grid-template-columns: 1fr;
        gap: 20px;
    }
}
//basics
@import "/src/style/base/variables";
@import "/src/style/base/helpers";
@import "/src/style/base/font-face";
@import "/src/style/base/mixins";
@import "/src/style/base/themes";

//pages
@import "/src/style/pages/home";
@import "src/style/pages/profile";
@import "src/style/pages/stream";
@import "src/style/pages/error";
@import "src/style/pages/tickets";
@import "src/style/pages/login";
@import "/src/style/pages/creator";
@import "/src/style/pages/landing";

//components
@import "/src/style/cmps/header";
@import "/src/style/cmps/filter-bar";
@import "/src/style/cmps/magic-button";
@import "/src/style/cmps/discover";
@import "/src/style/cmps/countdown";
@import "/src/style/cmps/feed";
@import "/src/style/cmps/subscriptions";
@import "/src/style/cmps/comment";
@import "/src/style/cmps/show-card";
@import "src/style/cmps/menu";
@import "src/style/cmps/popup";
@import "src/style/cmps/options-bar";
@import "src/style/cmps/popup-edit-profile";
@import "src/style/cmps/wallet-connect";
@import "src/style/cmps/profile-statistics";
@import "src/style/cmps/search-bar";
@import "src/style/cmps/accessibility";
@import "src/style/cmps/loader";
@import "src/style/cmps/footer";
@import "src/style/cmps/event-box";
@import "src/style/cmps/event-box-fund";
@import "src/style/cmps/premium";
@import "src/style/cmps/creator-card";
@import "src/style/cmps/event-box-loader";
@import "src/style/cmps/chat-box";
@import "src/style/cmps/stream-chat";
@import "src/style/cmps/players-list";
@import "src/style/cmps/upper-popup";
@import "src/style/cmps/simple-popup";
@import "src/style/cmps/tutorial";
@import "src/style/cmps/popup-upcoming";
@import "src/style/cmps/popup-details";
@import 'src/style/cmps/popup-video';
@import "src/style/cmps/popup-pay";
@import "src/style/cmps/wallpaper";

* {
  box-sizing: border-box;
  font-family: rubik-medium;
  scroll-behavior: smooth;
}

html {
  padding-right: 0 !important;
  overflow-y: scroll;
  direction: rtl;
}

.temp {
  height: 100vh;
  @include flex(20px, center, center, column);
  color: white;
  padding: 10px;

  .inner {
    padding-bottom: 120px;
    @include flex(20px, center, center, column);
  }

  img {
    width: 325px;
  }

  h1{
    margin: 5px;
    text-decoration: underline;
    color:$main;
  }
  p{
    font-size: 40px;
  }
}

body {
  margin: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background: $back-dark;
}

textarea {
  resize: none;
}

textarea:focus,
input:focus {
  outline: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
}

@media (max-width: 930px) {
  body {
    height: unset;
  }
}

body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background: transparent;
}

.main-layout {
  margin-top: 60px;
  margin-bottom: 50px;
}

input[type='range'] {
  overflow: hidden;
  width: 130px;
  -webkit-appearance: none;
  background-color: $bright-back;
  height: 10px;
  border-radius: 10px;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  color: black;
  margin-top: -1px;
}

input[type='range']::-webkit-slider-thumb {
  width: 12px;
  -webkit-appearance: none;
  height: 30px;
  cursor: pointer;
  background: $sec;
  box-shadow: -80px 0 0 80px $main;
}
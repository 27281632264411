.event-box-fund {
    @include flex(0, center, center, column);
    border: 4px solid $bright-back;
    border-radius: 15px;
    width: 900px;
    background: $back-dark;

    .event-details {
        width: 100%;
        @include flex(30px, space-between, center);
        background-color: $bright-back;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        padding: 0 10px;

        .name-img {
            @include flex(10px, flex, center);
            cursor: pointer;
            width: 245;

            img {
                width: 100px;
                aspect-ratio: 1/1;
                object-fit: cover;
                border-radius: 50%;
            }

            p {
                font-size: 18px;
                margin: 0;
                max-width: 210px;
                word-break: break-all;
            }
        }

        .timer-holder {
            @include flex(10px, center, center, column);
            // padding: 20px 0;
            // padding-left: 30px;
            align-self: center;

            p {
                font-size: 32px;
                margin: 0;
            }

            .timer {
                @include flex(0, center, center);
                font-size: 44px;
                margin: 0;
                padding: 0;
            }
        }

        .pricepool-holder {
            @include flex(8px, center, flex-end, column);
            padding: 20px 0;
            padding-top: 0;
            width: 245px;

            div {
                @include flex(0, flex-start, center);
                width:120px;
            }

            .watchers{
                width: 30px;
                height: 30px;
                object-fit: cover;
                margin-left: 7px;
            }

            .sand-watch {
                height: 50px;
                width: 40px;
                object-fit: cover;
                opacity: 0.8;
            }

            .date-holder {
                @include flex(10px, flex-start, flex-start, column);

                p {
                    margin: 0;
                    text-align: center;
                }
            }
        }
    }

    .thumbnail{
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        border-radius: 10px;
    }

    .player-description {
        @include flex(0, center, center, column);
        width: 100%;
        padding: 0 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        color: white;
        word-break: break-word;

        a{
            color:$main;
        }

        h1 {
            margin: 15px;
            font-weight: 100;
            font-size: 36px;
            text-align: center;
        }

        p {
            margin-top: 0;
            margin-bottom: 10px;
            font-size: 22px;
            opacity: 0.7;
            line-height: 30px;
            width: 90%;
            text-align: center;
            word-break: break-word;
        }

        .investors-pool {
            width: 90%;
            @include flex(0, flex-start, center);
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            height: 30px;
            border: 1px solid $main;
            margin-bottom: 30px;
            position: relative;

            p {
                margin: 0;
                font-size: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                opacity: 1;
            }

            div {
                background-color: $sec;
                height: 100%;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
            }
        }
    }
}

.support {
    @include flex(40px, center, center);
    height: 60px;
    margin: auto;
    margin-top: 20px;
    position: relative;
    margin-bottom: 20px;

    button {
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        font-size: 30px;
        border: none;
        background-color: $main;
        height: 40px;
        color: white;
        width: 200px;
        font-weight: 500;
    }

    .bought{
        background-color: $bright-back;
    }
}

@media (max-width: 1200px) {
    .event-box-fund {
        border-radius: 10px;
        width: 95vw;

        .event-details {
            @include flex(0px, space-between, center);
            padding: 0 10px;

            .date-holder {
                gap: 0 !important;
                width: fit-content !important;
            }

            .timer-holder {
                padding: 10px 0;

                .timer {
                    font-size: 28px;
                }
            }

            .pricepool-holder {
                div {
                    @include flex(0, center, center);
                    width: 200px;
                }

                p {
                    font-size: 18px !important;
                }

                img {
                    height: 30px;
                }
            }

            .name-img {
                @include flex(0, flex-start, center);

                p {
                    display: none;
                }

                img {
                    width: 80px;
                }
            }
        }

        .player-description {
            padding: 0 10px;

            h1 {
                margin: 15px;
                font-size: 28px;
            }

            p {
                margin-bottom: 24px;
                font-size: 22px;
                line-height: 24px;
            }

        }
    }

    .support {
        margin-top: 0px;
        width: calc(500px + 10%);

        div {
            width: 40px;
            font-size: 24px;
        }

        input {
            font-size: 24px;
        }

        button {
            width: 130px;
            font-size: 24px;
        }
    }
}

@media (max-width: 700px) {
    .event-box-fund {
        .event-details {
            .name-img {
                width: 20%;

                img {
                    width: 50px;
                }
            }

            .timer-holder {
                @include flex(0, center, center, column);
                padding: 10px 0;
                padding-left: 0px;
                width: 60%;

                p {
                    font-size: 20px;
                }

                .timer {
                    font-size: 24px;
                }
            }

            .pricepool-holder {
                padding: 10px 0;
                width: 20%;
                @include flex(8px, center, flex-start, column);

                div {
                    width: max-content;
                }

                .sand-watch {
                    height: 30px;
                    width: 20px;
                }

                p {
                    font-size: 14px !important;
                    margin: 0;
                }

                .inv{
                    display: none;
                }
            }
        }

        .player-description {
            h1 {
                font-size: 20px;
            }

            p {
                font-size: 14px;
                line-height: 14px;
            }

            .investors-pool {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                height: 20px;

                p {
                    font-size: 14px;
                }

                div {
                    border-bottom-right-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }
        }
    }

    .support {
        width: 90vw;

        div {
            font-size: 16px;
            outline: 1px solid $main;
            border-radius: 8px;
            height: 30px;
            width: 30px;
        }

        input {
            font-size: 16px;
        }

        button {
            width: 200px;
            font-size: 20px;
        }
    }
}
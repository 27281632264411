.creator-card {
    width: 100%;
    @include flex(10px, flex-start, center);
    padding: 20px;
    border-radius: 10px;
    background: radial-gradient(circle at center, $back-dark , $bright-back);
    cursor: pointer;

    img {
        width: 200px;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: 50%;
    }

    .creator-card-details {
        @include flex(0, space-between, flex-start, column);
        height: 200px;
        flex: 1;
        h3, p {
            margin: 0;
        }
        h3{
            font-size: 24px;
            margin-bottom: 5px;
            color: $main;
        }
        p {
            font-size: 18px;
        }
        div {
            @include flex(10px, flex-start, center);

            span{
                color: $main;
                font-size: 22px;
            }

            a {
                img {
                    width: 30px;
                    aspect-ratio: 1/1;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
        }
    }
}

@media (max-width: $mobile) {
    .creator-card-wrapper {
        padding: 10px;
    }
    
    .creator-card {
        padding: 10px;
    
        img {
            width: 140px;
        }
    
        .creator-card-details {
            height: 140px;
            h3{
                font-size: 18px;
                margin-bottom: 4px;
            }
            p {
                font-size: 16px;
            }
            div {
                @include flex(5px, flex-start, center);
    
                span{
                    color: $main;
                    font-size: 18px;
                }
    
                a {
                    img {
                        width: 25px;
                        aspect-ratio: 1/1;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}
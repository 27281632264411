.filter-bar{
	@include flex(20px,flex-start);
    width: 99%;
	white-space: nowrap;
	position: fixed;
	top:55px;
	padding: 12px;
	z-index: 1;
	
	& :nth-child(3){
		margin-left: 30px;
	}
	div{
		font-size: 15px;
		padding: 6px 12px;
		border: 2px rgb(255, 255, 255) solid;
		border-radius: 15px;
		box-shadow: 0px 3px 4px 0px $main;
	}
	.filter-nav{
		@include flex();
		padding: 2px;
		height: 34px;
		position: absolute;
		z-index: 1;
		color: $main;
		position: fixed;
		top: 67px;
		right: 10px;
		width: fit-content;
		z-index: 1;
	}
}

@media (max-width: $mobile) {
    .filter-bar{
		& :nth-child(3){
			margin-left: 10px;
		}
		div{
			padding: 4px 8px;
			box-shadow: 0px 2px 4px 0px $main;
		}
	}
}




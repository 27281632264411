.home {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    color: white;

    .featured-banner {
        width: 100%;
        height: 200px;
        cursor: pointer;
        margin-bottom: 80px;
        position: sticky;
        top: -50px;
        background-image: url('../imgs/home-banner.webp');
        background-size: cover;
        z-index: 1;
        background-position-y: -800px;

        .featured-text {
            width: 340px;
            position: absolute;
            top: 10px;
            right: 150px;
            font-size: 40px;
            font-weight: 400;
            cursor: pointer;

            p {
                margin-bottom: 8px;
                margin-top: 8px;
            }

            span {
                text-transform: capitalize;
                color: #f59d10;
            }

            .button-wrapper {
                margin-top: 22px;

                button {
                    margin: auto !important;
                    background-color: $main;
                    color: $bright-text;
                    cursor: pointer;
                    padding: 1px 28px;
                    font-size: 32px;
                    border-width: 0;
                    border-radius: 10px;
                }
            }
        }
    }

    .events-wrapper {
        @include flex(150px, space-between, center, column);
    }

    .event-box-wrapper {
        @include flex(0, center, center, column);
        margin-bottom: 30px;
        margin-top: 30px;

        .event-box-upper {
            @include flex(20px, flex-start, center);
            padding-bottom: 10px;
            width: 100%;

            div {
                @include flex(0, center, center);
                background-color: $bright-back;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                font-size: 22px;
                cursor: pointer;
            }
        }
    }

    .not-found {
        @include flex(15px, center, center, column);
        flex: 1;

        img {
            width: 220px;
        }

        p {
            text-align: center;
            font-size: 25px;
            margin: 0px;
        }

        .pay {
            font-size: 30px;
            text-align: center;
            padding: 10px 40px;
            background-color: $main;
            border-radius: 10px;
            cursor: pointer;
            margin: 20px;
        }
    }

    @media (max-width: 1500px) {
        .disappearable {
            display: none !important;
        }

        .event-box-wrapper {
            @include flex(10px, flex-start, center, column);

            .event-box-upper {
                @include flex(10px, flex-start, center);
                padding-bottom: 5px;

                div {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    font-size: 16px;
                }
            }
        }
    }
}

@media (max-width: $mobile) {
    .home {
        .featured-banner {
            top: -70px;
            background-position-y: bottom;
            margin-bottom: 8px;

            .featured-text {
                width: 230px;
                text-align: center;
                font-size: 24px;
                padding-top: 10px;
                position: sticky;

                .button-wrapper {
                    margin-top: 10px;
                    padding-top: 15px;
                    position: sticky;
                    top: 70px;
                    padding-bottom: 20px;

                    button {
                        font-size: 24px;
                        padding: 2 8px;
                        top: 300px;
                    }
                }
            }
        }

        .events-wrapper {
            @include flex(30px, space-between, center, column);
            padding-bottom: 20px;
        }

        .not-found {
            padding: 10px;
            padding-bottom: 40px;

            img {
                width: 120px;
            }

            p {
                font-size: 16px;
                padding: 0 10px;
            }
        }
    }
}